.audio-tracklist-container {
  margin-top: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
}

.audio-tracklist-container > * {
  margin-bottom: 60px;
}

.action-link,
.library-item {
  cursor: pointer;
  margin-bottom: 7px;
  color: var(--lightItem);
}

.action-link:hover,
.library-item:hover {
  color: var(--hoverItem);
}

.share-modal-container {
  width: 400px;
  height: 300px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.share-modal-textfield-container {
  display: flex;
  flex-direction: column;
}

.tracklist-container {
  width: 100%;
  height: 154px;
  border: 2px dashed orange;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  /* -webkit-overflow-scrolling: auto; */
}
