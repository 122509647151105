.user-connection-cursor {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
}

.user-connection-cursor img {
  width: 60px;
  height: 60px;
}

.player-page-container .player-logo {
  margin: 0;
}
