.leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  align-items: center;
  margin: 20px 0 30px 0;
}
.leaderboard-title {
  display: flex;
  justify-content: center;
}

.leaderboard-name,
.leaderboard-score {
  width: 100px;
  padding: 5px;
  padding-right: 0;
  display: flex;
}
.leaderboard-medal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.leaderboard-medal > img {
  width: 30px;
  height: 30px;
}
