.widget-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: absolute;
  cursor: move;
  border: 1px solid black;
  background-color: white;
  min-height: 200px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}
.joke-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 10px 10px;
}
@media screen and (max-width: 350px) {
  .default {
    width: 150px;
  }
}
.default {
  width: 350px;
}
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Shadows+Into+Light&family=Passion+One&display=swap");
.getJoke {
  background-color: rgb(117, 192, 141);
  border: none;
  color: white;
  font-size: 1em;
  padding: 5px 10px;
  font-family: "Shadows Into Light", cursive;
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: 2s;
  animation-iteration-count: 1;
}
.title {
  text-align: center;
  color: red;
  font-size: 1em;
  font-family: "Permanent Marker", cursive;
  animation: changeColor 8s infinite;
}
.Joke {
  text-align: center;
  font-family: "Passion One", cursive;
  font-size: 1em;
  padding-top: 5px;
}
@keyframes changeColor {
  0% {
    color: rgb(241, 55, 55);
  }
  25% {
    color: rgb(214, 139, 26);
  }
  50% {
    color: rgb(46, 146, 212);
  }
  75% {
    color: rgb(202, 27, 150);
  }
  100% {
    color: rgb(241, 55, 55);
  }
}
@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.welcome-container {
  width: fit-content;
  height: fit-content;
  position: relative;
  top: 30px;
  align-self: center;
  margin: auto;
}

.instruction {
  position: absolute;
}

.high-score {
  position: absolute;
}

#emojis {
  width: 100vw;
  height: 220px;
  align-self: center;
  position: relative;
  /* border: 2px rgb(0, 204, 255) dashed; */
}

.live-emoji {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -2rem;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}

.point-container {
  position: relative;
  margin: auto;
  top: 0px;
  width: 300px;
  /* border: black 2px solid; */
}

.point-count {
  position: absolute;
  margin: auto;
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.clickzone {
  width: 100vw;
  height: 220px;
  position: absolute;
  align-self: center;
  /* border: 3px red dashed; */
  vertical-align: middle;
}

.live-gif {
  width: 35px;
  height: 35px;
}

.special-bonus-text {
  font-size: 25px;
  font-style: italic;
  position: absolute;
  color: rgb(255, 115, 0);
  top: -220px;
  left: -15rem;
}

.url-title-container {
  position: absolute;
  color: orangered;
}

.url-container {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  color: rgb(0, 140, 255);
  text-decoration: underline;
}

#live-emoji-sec {
  position: relative;
  margin-bottom: 1rem;
}


#bullet-sec {
  width: 100vw;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.bullet-text {
  font-size: 22px;
  position: absolute;
  color: orangered;
  width: fit-content;
}

.bullet-screen {
  width: 100vw;
  height: 200px;
  position: absolute;
  /* border: 3px solid orange; */
  pointer-events: none;
}

.font-dropdown-container {
  position: relative;
  width: 120px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  height: 35px;
  top: 7px;
  padding-right: 5px;
}

.bullet-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 2px;
  padding: 0.25rem 0.25rem;
  padding-bottom: 0.5rem;
  /* background: transparent; */
}

.bullet-input {
  position: relative;
  display: flex;
}

.bullet-input input {
  flex-grow: 1;
  color: rgb(97, 93, 93);
  width: 400px;
  align-content: center;
  font-size: 20px;
  line-height: 2rem;
  vertical-align: middle;
  border-style: none;
  /* background: transparent; */
  outline: none;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.bullet-input-container ::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(
    to right,
    #b294ff,
    #57e6e6,
    #feffb8,
    #57e6e6,
    #b294ff,
    #57e6e6
  );
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
}

/* .bullet-input-container > *:not(:first-child) {
  display: flex;
  justify-content: center;
  margin-left: 10px;
} */
