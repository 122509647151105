@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Shadows+Into+Light&family=Passion+One&display=swap);

:root {
  --lightItem: #888;
  --hoverItem: #111;
}

html,
body,
#root,
.App {
  width: 100%;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  /* background: url("/Polka-Dots.svg"); */
  /* background: url("/Polka-Dots.svg"); */
}

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-size: 1.3rem;
  color: #888;
  color: var(--lightItem);
}

.adventure-logo {
  padding: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}

.player-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 0 0 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.player-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.audio-tracklist-container {
  margin-top: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
}

.audio-tracklist-container > * {
  margin-bottom: 60px;
}

.action-link,
.library-item {
  cursor: pointer;
  margin-bottom: 7px;
  color: var(--lightItem);
}

.action-link:hover,
.library-item:hover {
  color: var(--hoverItem);
}

.share-modal-container {
  width: 400px;
  height: 300px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.share-modal-textfield-container {
  display: flex;
  flex-direction: column;
}

.tracklist-container {
  width: 100%;
  height: 154px;
  border: 2px dashed orange;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  /* -webkit-overflow-scrolling: auto; */
}

.widget-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: absolute;
  cursor: move;
  border: 1px solid black;
  background-color: white;
  min-height: 200px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}
.joke-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 10px 10px;
}
@media screen and (max-width: 350px) {
  .default {
    width: 150px;
  }
}
.default {
  width: 350px;
}
.getJoke {
  background-color: rgb(117, 192, 141);
  border: none;
  color: white;
  font-size: 1em;
  padding: 5px 10px;
  font-family: "Shadows Into Light", cursive;
  -webkit-animation-name: bounce-7;
          animation-name: bounce-7;
  -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
          animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.title {
  text-align: center;
  color: red;
  font-size: 1em;
  font-family: "Permanent Marker", cursive;
  -webkit-animation: changeColor 8s infinite;
          animation: changeColor 8s infinite;
}
.Joke {
  text-align: center;
  font-family: "Passion One", cursive;
  font-size: 1em;
  padding-top: 5px;
}
@-webkit-keyframes changeColor {
  0% {
    color: rgb(241, 55, 55);
  }
  25% {
    color: rgb(214, 139, 26);
  }
  50% {
    color: rgb(46, 146, 212);
  }
  75% {
    color: rgb(202, 27, 150);
  }
  100% {
    color: rgb(241, 55, 55);
  }
}
@keyframes changeColor {
  0% {
    color: rgb(241, 55, 55);
  }
  25% {
    color: rgb(214, 139, 26);
  }
  50% {
    color: rgb(46, 146, 212);
  }
  75% {
    color: rgb(202, 27, 150);
  }
  100% {
    color: rgb(241, 55, 55);
  }
}
@-webkit-keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.welcome-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: 30px;
  align-self: center;
  margin: auto;
}

.instruction {
  position: absolute;
}

.high-score {
  position: absolute;
}

#emojis {
  width: 100vw;
  height: 220px;
  align-self: center;
  position: relative;
  /* border: 2px rgb(0, 204, 255) dashed; */
}

.live-emoji {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -2rem;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.point-container {
  position: relative;
  margin: auto;
  top: 0px;
  width: 300px;
  /* border: black 2px solid; */
}

.point-count {
  position: absolute;
  margin: auto;
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.clickzone {
  width: 100vw;
  height: 220px;
  position: absolute;
  align-self: center;
  /* border: 3px red dashed; */
  vertical-align: middle;
}

.live-gif {
  width: 35px;
  height: 35px;
}

.special-bonus-text {
  font-size: 25px;
  font-style: italic;
  position: absolute;
  color: rgb(255, 115, 0);
  top: -220px;
  left: -15rem;
}

.url-title-container {
  position: absolute;
  color: orangered;
}

.url-container {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  color: rgb(0, 140, 255);
  text-decoration: underline;
}

#live-emoji-sec {
  position: relative;
  margin-bottom: 1rem;
}


#bullet-sec {
  width: 100vw;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.bullet-text {
  font-size: 22px;
  position: absolute;
  color: orangered;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bullet-screen {
  width: 100vw;
  height: 200px;
  position: absolute;
  /* border: 3px solid orange; */
  pointer-events: none;
}

.font-dropdown-container {
  position: relative;
  width: 120px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  height: 35px;
  top: 7px;
  padding-right: 5px;
}

.bullet-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 2px;
  padding: 0.25rem 0.25rem;
  padding-bottom: 0.5rem;
  /* background: transparent; */
}

.bullet-input {
  position: relative;
  display: flex;
}

.bullet-input input {
  flex-grow: 1;
  color: rgb(97, 93, 93);
  width: 400px;
  align-content: center;
  font-size: 20px;
  line-height: 2rem;
  vertical-align: middle;
  border-style: none;
  /* background: transparent; */
  outline: none;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.bullet-input-container ::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(
    to right,
    #b294ff,
    #57e6e6,
    #feffb8,
    #57e6e6,
    #b294ff,
    #57e6e6
  );
  background-size: 500% auto;
  -webkit-animation: gradient 3s linear infinite;
          animation: gradient 3s linear infinite;
}

/* .bullet-input-container > *:not(:first-child) {
  display: flex;
  justify-content: center;
  margin-left: 10px;
} */

.gif-preview-container {
  width: 400px;
  height: 600px;
  border: 1px dashed purple;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  z-index: 2;
  background-color: white;
}

.add-gif-container {
  width: 200px;
  height: 200px;
  border: 1px dashed blue;
  align-items: center;
  display: flex;
  justify-content: center;
}

.width-100-centered {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gif-preview-title {
  margin: 10px 0;
}

.editable-textfield-container {
  display: flex;
}

.user-connection-cursor {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
}

.user-connection-cursor img {
  width: 60px;
  height: 60px;
}

.player-page-container .player-logo {
  margin: 0;
}

.leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  align-items: center;
  margin: 20px 0 30px 0;
}
.leaderboard-title {
  display: flex;
  justify-content: center;
}

.leaderboard-name,
.leaderboard-score {
  width: 100px;
  padding: 5px;
  padding-right: 0;
  display: flex;
}
.leaderboard-medal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.leaderboard-medal > img {
  width: 30px;
  height: 30px;
}

.music-controller-container {
  background: transparent;
  color: gray;
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* padding-right: 1.3rem; */
  margin-top: 1.6rem;
  /* margin-bottom: 1.2rem; */
  align-self: center;
  display: flex;
}

.music-controller-container .MuiButtonBase-root {
  color: gray !important;
}

.music-controller-emoji-container {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-page-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
}

.player-body {
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
  position: relative;
}

.emoji-picker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 280px;
  height: 320px;
}

.error-container {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: red;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
}

.url-popover-container > .MuiPaper-root {
  position: absolute;
  margin: 0 auto;
  left: 0 !important;
  right: 0 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.emoji-panel-container {
  width: 400px;
  display: flex;
  overflow-x: hidden;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;
  padding-top: 1rem;
}

.leaderboard-plays-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.plays-container {
  background: white;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.streak-modal-container {
  width: 500px;
  height: 200px;
  background: white;
  margin: 0 auto;
  margin-top: calc(50vh - 250px);
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.back-next-container {
  display: flex;
  width: 400px;
  justify-content: center;
  background: white;
}

.collaboration-container {
  width: 400px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 100vw;
}

@media screen and (max-width: 500px) {
  .music-controller-container {
    max-width: 100%;
  }
  .error-container {
    font-size: 25px;
    width: 300px;
  }
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3em;
}

.upload-container .adventure-logo {
  margin-bottom: 0;
}
.upload-container > *:not(:last-child) {
  margin-bottom: 50px !important;
}

.upload-preview-url {
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
  max-width: 100vw;
  overflow-wrap: break-word;
}

.upload-preview-url-string {
  text-decoration: underline;
  margin-left: 10px;
}

.upload-error {
  color: red;
}

.upload-latest-submitted {
  display: flex;
}

.upload-latest-submitted > a {
  margin-left: 10px;
}

.upload-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}
.visit-player-page-container {
  display: flex;
  flex-direction: column;
}

.visit-player-page-container > * {
  margin-bottom: 5px;
}

.about-us {
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
  color: var(--lightItem);
}

.space-below {
  margin-bottom: 10px;
}

.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.width-100 {
  width: 100%;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}

@media screen and (max-width: 500px) {
  .upload-preview-url {
    font-size: 20px;
  }
  .visit-player-page-container a {
    font-size: 14px;
  }
}

.popover_gif {
  width: 40vw;
  height: 55vw;
}

