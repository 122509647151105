.music-controller-container {
  background: transparent;
  color: gray;
  width: auto;
  height: fit-content;
  /* padding-right: 1.3rem; */
  margin-top: 1.6rem;
  /* margin-bottom: 1.2rem; */
  align-self: center;
  display: flex;
}

.music-controller-container .MuiButtonBase-root {
  color: gray !important;
}

.music-controller-emoji-container {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-page-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
}

.player-body {
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.emoji-picker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 280px;
  height: 320px;
}

.error-container {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: red;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
}

.url-popover-container > .MuiPaper-root {
  position: absolute;
  margin: 0 auto;
  left: 0 !important;
  right: 0 !important;
  width: fit-content;
}

.emoji-panel-container {
  width: 400px;
  display: flex;
  overflow-x: hidden;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;
  padding-top: 1rem;
}

.leaderboard-plays-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.plays-container {
  background: white;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.streak-modal-container {
  width: 500px;
  height: 200px;
  background: white;
  margin: 0 auto;
  margin-top: calc(50vh - 250px);
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.back-next-container {
  display: flex;
  width: 400px;
  justify-content: center;
  background: white;
}

.collaboration-container {
  width: 400px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 100vw;
}

@media screen and (max-width: 500px) {
  .music-controller-container {
    max-width: 100%;
  }
  .error-container {
    font-size: 25px;
    width: 300px;
  }
}
